article {
  &.feature-publication-teaser {
    @include media-breakpoint-up(md) {
      border: 1px solid $cigi-medium-grey;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
      max-width: 300px;
      min-height: 300px;
      padding: 1em;
      position: relative;
      width: 100%;

      &::after {
        border-color: $cigi-medium-grey $white;
        border-style: solid;
        border-width: 20px 20px 0 0;
        bottom: -1px;
        content: '';
        position: absolute;
        right: -1px;
      }
    }
    border-bottom: 1px solid $cigi-light-grey;
    margin-bottom: 1.5em;
    padding-bottom: 1.5em;

    .feature-publication-teaser-meta {
      margin-top: 1em;

      .button-action {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        font-size: 0.75em;
        height: 25px;
        justify-content: center;
        margin-top: 0.5em;
        padding: 7px 10px;
        width: 25px;

        &:hover {
          i {
            color: $white;
          }
        }

        i {
          color: $cigi-primary-colour;
          margin-right: 0;
          transition: color 0.25s ease;
        }
      }

      .date {
        color: $cigi-text-grey;
        font-size: 0.75em;
        text-transform: uppercase;
      }
    }

    .feature-publication-teaser-title {
      .topics {
        color: $cigi-primary-colour;
        margin-bottom: 0.5em;
      }
    }
  }
}
