section {
  &.publication-hero {
    @include media-breakpoint-up(md) {
      padding-top: 6em;
    }
    background-color: transparent;
    color: $black;
    display: block;
    margin-bottom: 2em;
    padding-top: 3em;
    text-align: center;

    h1 {
      @include media-breakpoint-up(md) {
        font-size: 2.75em;
      }
      font-size: 1.75em;
      font-weight: 400;
      line-height: 1.1em;
    }

    h2 {
      color: $black;
      font-size: 1.1em;
      font-weight: 400;
    }

    .custom-text-list {
      justify-content: center;
    }

    .topics {
      margin: 1em 0;
    }

    .publication-series {
      @include link($color: $cigi-text-grey, $hover-color: $cigi-primary-colour);
      color: $cigi-text-grey;
    }
  }
}


// BUTTONS

button {
  .fa-download {
    margin-right: 0.25em;
  }
}
