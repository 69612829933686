.feature-content {
  &.feature-event {
    display: flex;
    justify-content: space-between;

    &.past-event {
      .event-date-circle {
        border-color: $cigi-text-grey;
        color: $cigi-text-grey;
      }
    }

    h3 {
      font-size: 1.0625em;
      line-height: 1.4;
    }

    .event-date-circle {
      @include date-circle($border-color: $black, $circle-size: 68px, $font-size: 1em);
      font-weight: 500;
      margin-left: 1em;
    }

    .button-action {
      margin-top: 1em;

      &.button-private-event {
        border: 1px solid $cigi-text-grey;
        color: $cigi-text-grey;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
