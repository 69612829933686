section {
  &.body {
    margin-bottom: 2em;
  }

  &.hero {
    margin-bottom: 3em;
  }

  &.main {
    ol,
    ul {
      line-height: 1.5em;
      margin: 0 0 1em 1em;
      padding: 0;
    }
  }

  &.featured-section {
    .featured-section-title {
      border-top: 3px solid $black;
      color: $black;
      display: inline-block;
      font-size: 0.75em;
      font-weight: 500;
      margin-bottom: 1em;
      text-transform: uppercase;
    }
  }
}
