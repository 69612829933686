ul {
  &.social-share-list {
    @include link($color: $cigi-light-grey, $hover-color: $cigi-primary-colour);
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 1em;
    padding-left: 0;

    &.no-stack {
      li {
        &:first-child {
          a {
            padding-left: 0;
          }
        }

        &:last-child {
          a {
            margin-right: 0;
            padding-right: 0;
          }
        }

        a {
          margin-right: 0.5em;
          padding: 0.2em 0.5em;
        }
      }
    }

    .facebook-share-link {
      cursor: pointer;
    }

    .fa-brands {
      color: $black;
    }
  }
}
