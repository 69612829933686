.about-the-author {
  margin: 3em 0;

  h3 {
    border-top: 3px solid $black;
    display: inline-block;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.4em;
    margin: 0;
    text-transform: uppercase;
  }

  .name {
    @include link($color: $black, $hover-color: $cigi-primary-colour);
    font-size: 1.0625em;
    font-weight: 400;
    margin-bottom: 0.25em;
  }

  .author-block {
    border-bottom: solid 1px $cigi-light-grey;
    padding: 1em 0;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}
