.stream-block-blockquote {
  padding-bottom: 1em;
  padding-top: 1em;

  blockquote {
    color: $cigi-dark-grey;
    font-size: 1.5em;
    line-height: 130%;
  }

  img {
    @include media-breakpoint-up(sm) {
      display: block;
      height: 150px;
      margin: 0.625em 0.625em 0 0;
      width: 150px;
    }

    display: none;
  }

  p {
    margin: 0;
  }

  .stream-block-blockquote-content {
    margin-left: 0;
    padding: 1em 2em 0;
    position: relative;

    &.has-image {
      @include media-breakpoint-up(sm) {
        margin-left: 1em;
        padding: 1em 0;
      }

      margin-left: 0;
      padding: 1em 2em;
    }

    &::before {
      border-bottom: 5px solid $cigi-primary-colour;
      content: '';
      height: 5px;
      left: 0;
      position: absolute;
      top: 0;
      width: 5em;
    }

    .stream-block-blockquote-author {
      font-size: 1em;
      font-weight: 700;
      line-height: 1;
    }

    .stream-block-blockquote-author-title {
      font-size: 1em;
    }
  }
}
