.feature-content-from-the-archives {
  color: $cigi-primary-colour;
  font-size: 0.75rem;
  font-style: italic;
  text-transform: uppercase;
}

.from-the-archives-blurb {
  border: 1px solid $cigi-primary-colour;
  color: $cigi-text-grey;
  font-style: italic;
  padding: 1em;
}
