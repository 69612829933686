.feature-content {
  &.dark-mode {
    .feature-content-topic-list,
    .feature-content-people-list,
    .feature-content-title {
      @include link($color: $white, $hover-color: $cigi-dark-pink);
      color: $white;

      li {
        color: $white;

        &::after {
          color: $white;
        }
      }
    }

    .feature-content-subtitle,
    .feature-content-date {
      color: $cigi-medium-grey;
    }

    .feature-content-topic-list {
      a {
        color: $cigi-primary-colour-dark-bg;
      }

      li {
        &::after {
          color: $cigi-primary-colour-dark-bg;
        }
      }
    }
  }

  .feature-content-topic-list {
    font-size: 0.75em;
    font-weight: 500;
    margin-bottom: 0.5em;

    li {
      &::after {
        color: $cigi-primary-colour;
      }

      a {
        text-transform: uppercase;
      }
    }
  }

  .feature-content-people-list {
    @include link($color: $cigi-text-grey, $hover-color: $cigi-dark-pink);
    font-size: 0.75em;
    margin-bottom: 0;
    text-transform: uppercase;

    li {
      color: $cigi-text-grey;
      display: inline;
    }
  }

  .feature-content-title {
    @include link($color: $black, $hover-color: $cigi-dark-pink);
    line-height: 1.25;
    margin-bottom: 0.25em;
  }

  .feature-content-subtitle {
    color: $cigi-text-grey;
    line-height: 1.5;
    margin: 1em 0;
  }
}

section {
  &.featured {
    .row {
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
      flex-direction: column;
    }

    hr {
      margin-bottom: 1.5em;
      margin-top: 1.5em;
    }
  }
}

.feature-border-right {
  @include media-breakpoint-up(md) {
    border-right: 1px solid $cigi-light-grey;
  }
}
