section {
  &.publication-hero-book {
    color: $white;

    .topics {
      @include link($color: $white, $hover-color: $cigi-primary-colour);
    }

    h1 {
      @include media-breakpoint-up(md) {
        font-size: 2.75em;
      }
      font-size: 2em;
      font-weight: 400;
      margin-bottom: 0.5em;
    }

    .meta {
      color: $white;
      display: flex;
      font-size: 1.125em;
      text-transform: none;
    }

    .label {
      padding-right: 0.2em;
      white-space: nowrap;
    }

    .authors {
      @include link($color: $white, $hover-color: $cigi-primary-colour);
      font-size: 1.125em;
      margin-bottom: 0;
      margin-top: 0;
    }

    .social-share-list {
      justify-content: left;
      margin: 1em 0 0;

      li {
        &:first-child {
          margin-left: -4px;
        }
      }
    }

    .date {
      color: $white;
    }

    .button-list {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      display: flex;
      flex-direction: row;
      list-style: none;
      margin-top: 0.75em;

      li {
        &:first-child {
          a {
            margin-left: 0;
          }
        }
      }

      a {
        @include media-breakpoint-up(md) {
          margin: 1em 0.5em 0;
        }
        background-color: $color--blue;
        border-radius: 2px;
        color: $white;
        display: block;
        font-size: 0.75em;
        line-height: 1.333em;
        margin: 1em 0 0;
        padding: 7px 10px;
        transition: background-color 0.25s ease;

        &:hover {
          background-color: $color-dark-blue;
          color: $white;
        }
      }
    }
  }

  &.book-excerpt-download {
    margin: 3em 0;
    text-align: center;

    .button-action {
      text-transform: capitalize;
    }
  }
}
