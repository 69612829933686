header {
  background-color: $black;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color 0.3s ease;
  width: 100%;
  z-index: 15;

  &.small {
    .cigi-top-bar {
      height: 50px;

      .cigi-logo {
        .large-logo {
          opacity: 0;
        }

        .small-logo {
          opacity: 1;
        }
      }

      .desktop-menu {
        opacity: 0;

        a {
          pointer-events: none;
        }
      }
    }
  }

  .main-navigation-wrapper {
    position: relative;
    z-index: 4;
  }

  .cigi-top-bar {
    @include media-breakpoint-up(lg) {
      height: 70px;
    }

    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    transition: height 0.5s ease;

    .desktop-menu {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    &.scrolled-nav {
      height: 50px;

      .cigi-logo {
        .large-logo {
          opacity: 0;
        }

        .small-logo {
          opacity: 1;
        }
      }

      .desktop-menu {
        opacity: 0;

        a {
          pointer-events: none;
        }
      }
    }

    .cigi-logo {
      @include media-breakpoint-up(lg) {
        padding: 0.7em 0;
      }

      align-items: center;
      display: flex;

      svg {
        fill: $white;
        transition: opacity 0.3s ease;
      }

      .small-logo {
        @include media-breakpoint-up(lg) {
          opacity: 0;
        }

        height: 60%;
        opacity: 1;
        position: absolute;
      }

      .large-logo {
        @include media-breakpoint-up(lg) {
          display: block;
        }

        display: none;
        width: 8em;
      }
    }

    nav {
      display: flex;

      ul {
        align-items: center;
        display: flex;
        list-style: none;
        margin: 0;

        a,
        button {
          color: $white;
          font-size: 0.875rem;
          padding: 1.9em 0.9em;

          &:hover {
            color: $cigi-primary-colour;
          }
        }

        li {
          @include media-breakpoint-up(lg) {
            display: inline-block;
          }

          display: none;

          a {
            padding-left: 1em;
            padding-right: 1em;
          }
        }

        .overlay-controls {
          display: flex;

          &.menu-break {
            margin-left: 1.5em;
          }

          .overlay-controls-button {
            @include media-breakpoint-up(lg) {
              padding: 2em 0.5em;
            }
            padding: 0.7em 0.5em;
          }
        }
      }
    }
  }

  // NAVIGATION SEARCH POPUP

  .custom-nav-popup-btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
    width: 22px;

    span {
      left: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      right: 0;
      transition: transform 0.3s ease;
    }

    .icon-opened {
      transform: scale(0, 0);
    }

    .icon-closed {
      transform: scale(1, 1);
    }

    &.open {
      .icon-closed {
        transform: scale(0, 0);
      }

      .icon-opened {
        transform: scale(1, 1);
      }
    }
  }

  .custom-popup-wrapper {
    @include media-breakpoint-up(md) {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    background: $popup-overlay;
    height: 100vh;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    &::-webkit-scrollbar {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &.popup-search {
      .custom-popup-inner {
        @include media-breakpoint-up(lg) {
          align-items: center;
        }

        .container {
          @include media-breakpoint-up(lg) {
            margin-top: -33vh;
          }
        }
      }
    }

    &.popup-menu {
      &.opened-popup {
        overflow: scroll;

        .custom-popup-inner {
          height: auto;
          min-height: 100%;
        }
      }
    }

    .custom-popup-inner {
      @include media-breakpoint-up(lg) {
        height: 100%;
        padding-top: 0;
      }
      align-items: flex-start;
      background: $black;
      display: flex;
      height: 100%;
      left: 0;
      opacity: 0.3;
      padding-top: 86px;
      position: absolute;
      top: 0;
      transform: translate(0, -100%);
      transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
      width: 100%;

      &.overlay-menu {
        @include media-breakpoint-up(md) {
          height: auto;
        }
        height: 100%;
        padding-top: 90px;

        .overlay-menu-content {
          .overlay-menu-submenu {
            @include link($color: $white, $hover-color: $cigi-dark-pink);
            @include media-breakpoint-up(sm) {
              padding-left: 22px;
            }
            @include media-breakpoint-up(md) {
              border-bottom: 0;
              border-right: solid 1px $cigi-darkest-grey;
              font-size: 1.3em;
              margin-bottom: 2em;
              min-height: 250px;
              padding: 0 0.4em 0 1.3em;
            }
            border-bottom: 1px solid $divider-grey;
            font-size: 1.1em;
            padding: 1em 15px;
            position: relative;

            &:nth-child(4) {
              border-right: 0;
            }

            &:last-child {
              border-bottom: 0;
              border-right: 0;
            }

            &:nth-child(1),
            &:nth-child(5) {
              @include media-breakpoint-up(md) {
                padding-left: 15px;
              }
            }

            .accordion {
              @include media-breakpoint-up(md) {
                display: none;
              }
              color: $white;
              float: right;

              i {
                font-size: 0.8em;
              }
            }

            ul {
              @include link(
                $color: $cigi-medium-grey,
                $hover-color: $cigi-dark-pink
              );
              @include media-breakpoint-up(md) {
                max-height: 100%;
              }
              font-size: 0.75em;
              margin: 0;
              max-height: 0;
              overflow: hidden;
              transition: max-height 0.2s ease-out;

              li {
                list-style-type: none;
              }

              &.overlay-menu-footer-menu {
                @include link(
                  $color: $cigi-medium-grey,
                  $hover-color: $cigi-dark-pink
                );
                margin-bottom: 1em;
                max-height: 100%;

                li {
                  @include media-breakpoint-up(md) {
                    display: block;
                  }
                  display: inline-block;
                  margin: 0 0.8em 0.5em 0;
                }
              }

              &.overlay-menu-social-links {
                @include link($color: $white, $hover-color: $cigi-dark-pink);
                max-height: 100%;

                li {
                  display: inline-block;
                  margin-right: 0.7em;
                }
              }
            }
          }
        }
      }
    }

    .custom-popup-content {
      color: $white;
      margin: 0 auto;
      max-width: 50em;
      width: 100%;

      .input-group-search {
        border-bottom: 3px solid $white;
        height: 2.5em;

        .form-control {
          background-color: $black;
          color: $white;
          font-size: 1.8em;
          height: 100%;
          margin-right: 1em;

          &::-webkit-input-placeholder,
          &::placeholder {
            color: $white;
          }

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px $black inset;
            -webkit-text-fill-color: $white;
          }
        }

        .input-group-append {
          margin-left: 0.25em;

          .btn-search {
            font-size: 1.0625em;
            height: 100%;
          }
        }

        .nav-search-input-dropdown {
          opacity: 0;
          position: absolute;
          top: 4.5em;
          transition: opacity 0.5s ease;
          width: 100%;

          &.show {
            opacity: 1;
          }

          ul {
            @include link($color: $white, $hover-color: $cigi-primary-colour);
            list-style: none;
            margin: 1em 0 0;

            li {
              line-height: 1.2;
              margin-bottom: 0.35em;
            }
          }

          span {
            color: $cigi-medium-light-grey;
          }
        }
      }
    }

    &.opened-popup {
      opacity: 1;
      visibility: visible;

      .custom-popup-inner {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }
}

body {
  &.disable-scroll {
    header {
      .cigi-top-bar {
        .cigi-logo {
          svg {
            @include media-breakpoint-down(md) {
              * {
                fill: $white;
              }
            }
            @include media-breakpoint-up(md) {
              fill: $white;

              * {
                fill: $white;
              }
            }
            fill: $white;
          }
        }

        nav {
          ul {
            a,
            button {
              @include media-breakpoint-up(md) {
                color: $white;
              }
              color: $white;

              &:hover {
                color: $cigi-primary-colour;
              }
            }
          }
        }
      }
    }
  }
}
