.feature-content-large {
  @include image-button($size: 15vw);
  @include media-breakpoint-up(md) {
    @include image-button($size: 4em);
  }
  @include hover-darken;
  line-height: 1.25;

  &.poster {
    @include media-breakpoint-up(sm) {
      display: flex;
    }

    .feature-content-image {
      flex: 0 0 50%;
      padding-right: 15px;

      .img-wrapper {
        color: transparent;
        display: block;

        img {
          border: 1px solid $cigi-light-grey;
          box-shadow: 5px 5px 10px $cigi-light-grey;

          &:hover {
            border: 1px solid $white;
          }
        }
      }
    }

    .feature-content-text {
      @include media-breakpoint-up(sm) {
        padding-left: 15px;
      }
      margin-top: 0;
    }
  }

  .img-wrapper {
    margin-bottom: 1em;
  }

  .feature-content-text {
    margin-top: 0.75em;

    &.center-text {
      @include media-breakpoint-up(md) {
        margin: 0 auto;
        max-width: 36em;
        text-align: center;

        ul {
          justify-content: center;
        }
      }
    }

    .feature-content-title {
      @include media-breakpoint-up(md) {
        font-size: 2em;
      }
      font-size: 1.375em;
      line-height: 1.1;
    }

    .feature-content-subtitle {
      line-height: 1.25;
    }
  }

  .button-action {
    margin-top: .5em;
  }
}
