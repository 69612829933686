.person-page {
  .hero {
    .hero-main {
      .short-bio {
        color: $cigi-medium-light-grey;
      }
    }

    .portrait-circle {
      padding-top: 0.5em;

      img {
        border-radius: 50%;
        max-width: 150px;
      }
    }

    &.archived {
      background: $white;

      .hero-main {
        h1,
        .position {
          color: $black;
        }

        h1 {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
  }

  section {
    &.expertise {
      margin-bottom: 2em;

      .expertise-container {
        border: 1px solid $cigi-light-grey;
        padding: 1em;

        hr {
          &:first-child {
            display: none;
          }
        }

        .related-container {
          &:not(:last-child) {
            margin-bottom: 1em;
          }
        }

        .expertise-item {
          @include comma-separated;

          &:not(:only-child) {
            &::after {
              margin-right: 0;
            }
          }
        }

        .contact {
          @include media-breakpoint-up(md) {
            columns: 3;
          }
          columns: 2;

          .contact-item {
            @include link($color: $black, $hover-color: $cigi-dark-pink);
            line-height: 1.25em;

            a {
              display: inline-block;
              line-height: 1.25em;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              white-space: nowrap;
              width: calc(100% - 30px);
            }

            i {
              color: $cigi-primary-colour;
              line-height: 1.25em;
              margin-right: 0.3em;
              vertical-align: middle;
            }
          }
        }
      }
    }

    &.external-publications {
      margin-bottom: 3em;

      article {
        border-bottom: 1px solid $cigi-light-grey;
        display: flex;
        padding: 1em 0;

        .counter {
          margin-right: 0.5em;
        }
      }
    }
  }
}
