section {
  &.event-hero {
    @include media-breakpoint-up(md) {
      padding-top: 9em;
    }
    color: $white;

    .hero-container {
      display: flex;

      .event-hero-content {
        @include link($color: $white, $hover-color: $cigi-primary-colour);

        h1 {
          @include media-breakpoint-down(sm) {
            font-size: 1.75em;
          }
          font-weight: 400;
          line-height: 1.1em;
          margin-bottom: 0.5em;
        }

        .meta {
          color: $white;
          font-size: 1.125em;
          text-transform: none;
        }

        .speakers {
          display: flex;

          .custom-text-list > div {
            &::after {
              content: ', ';
            }

            &:last-child {
              &::after {
                content: '';
              }
            }
          }
        }

        .feature-content-topic-list,
        .custom-text-list {
          margin-left: 0.2em;
        }

        .feature-content-topic-list {
          margin-bottom: 1em;
        }

        .event-registration {
          margin-bottom: 0;

          a {
            display: block;

            &:hover {
              color: $white;
            }
          }
        }

        .location-string {
          @include link($color: $white, $hover-color: $cigi-primary-colour);

          i {
            color: $cigi-primary-colour;
            margin-right: 0.25em;
          }
        }
      }

      .event-date-circle {
        @include date-circle($border-color: $white, $circle-size: 195px, $font-size: 2.625em);
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}
