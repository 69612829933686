.promotion-block {
  background-color: $black;

  & > a {
    &:hover {
      .promotion-block-background {
        opacity: 0.8;
      }
    }
  }

  .promotion-block-background {
    background-size: cover;
    padding-bottom: 50%;
    transition: opacity 0.2s ease;
  }

  &.promotion-block-social {
    .promotion-block-background {
      background-position: right;
      background-size: cover;
      position: relative;

      &::before {
        @include media-breakpoint-up(md) {
          content: none;
        }
        background-color: $color--blue;
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }

    .social-content {
      @include media-breakpoint-up(md) {
        right: 0;
        width: 42%;
      }
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;

      .social-text {
        @include media-breakpoint-up(md) {
          font-size: calc(1vw + 0.5em);
          width: 70%;
        }
        color: $white;
        font-size: calc(3vw + 0.75em);
        line-height: 1.2;
        margin: 0 auto;
        text-align: center;
        width: 50%;
      }

      .social-links {
        display: flex;

        .social-link {
          align-items: center;
          background-color: $white;
          border: 1px solid $white;
          border-radius: 2px;
          color: $black;
          display: flex;
          height: 25px;
          justify-content: center;
          margin: 1em 0.3em 0;
          transition: background-color 0.2s ease;
          width: 25px;

          &:hover {
            background-color: $color--blue;
          }

          i {
            font-size: 12px;
          }
        }
      }
    }
  }

  &.promotion-block-wide {
    margin-top: 3em;

    .promotion-block-background {
      @include media-breakpoint-up(md) {
        padding-bottom: 19%;
      }
      background-position: center;
      position: relative;

      .promotion-block-background-small {
        @include media-breakpoint-up(md) {
          display: none;
        }
        position: absolute;
        width: 100%;
        z-index: 1;
      }
    }
  }

  // sass-lint:disable-block class-name-format
  &.promotion-block-podcast_player {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    @include media-breakpoint-up(md) {
      align-items: center;
    }
    background: none;
    border: 1px solid $bigtech-teal;
    display: flex;
    font-family: 'nunito-sans', 'futura-pt', sans-serif;
    justify-content: space-between;
    padding: 2em;

    p {
      line-height: 1.25;
    }

    .podcast-logo {
      color: $black;
      flex: 22.5% 0 0;
      font-weight: 500;

      .ad-block-bt-logo,
      .logo-text {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .ad-block-bt-logo,
      .ad-block-bt-logo-sm {
        margin-bottom: 0.5em;
      }

      .ad-block-bt-logo-sm {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    .podcast-image {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      flex: 22.5% 0 0;
    }

    .podcast-info {
      @include link($color: $black, $hover-color: $cigi-primary-colour);
      display: flex;
      flex: 50% 0 0;
      flex-direction: column;
      justify-content: center;

      .podcast-description {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        color: $cigi-text-grey;
      }

      .podcast-title {
        @include media-breakpoint-down(sm) {
          margin-bottom: 0.5em;
        }
        font-size: 18px;
        font-weight: 500;
        line-height: 1.25;
        margin-bottom: 0.2em;
      }
    }
  }
}
