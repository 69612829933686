footer {
  background: $black;
  color: $white;
  font-size: 0.75rem;
  padding: 5em 0 4em;
  position: relative;
  z-index: 10;

  .logo-container {
    margin: 0 auto 0.7em;
    width: 6em;

    .cigi-logo {
      fill: $white;
    }
  }

  .footer-bracket {
    @include media-breakpoint-up(md) {
      display: block;
    }

    display: none;
    width: 100%;
  }

  .highlighted-topics-list {
    @include media-breakpoint-up(md) {
      columns: 3;
    }
    @include media-breakpoint-up(lg) {
      columns: 4;
    }
    columns: 2;
    list-style-type: none;
    padding: 1.25em 0;

    .topic {
      @include link($color: $white, $hover-color: $cigi-primary-colour);
      padding: 0.2em;

      a {
        font-family: $primary-font-family;
        font-weight: $sans-regular;
        text-transform: uppercase;
      }
    }

    span {
      color: $cigi-medium-grey;
    }
  }

  .footer-content {
    font-size: 1rem;
    text-align: center;

    form {
      display: flex;
      height: 2em;
      margin-bottom: 3em;
    }

    input {
      &[type='email'] {
        background: $black;
        border-bottom: 2px solid $cigi-primary-colour;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        color: $white;
        font-size: 1rem;
        margin: 0;
        margin-right: 5px;
        width: calc(100% - 95px);

        &:focus {
          outline: none;
        }
      }

      &::placeholder {
        color: $white;
      }
    }

    button {
      background: $cigi-primary-colour;
      border-radius: 2px;
      color: $white;
      font-size: 14px;
      text-transform: uppercase;
      width: 90px;

      i {
        margin-right: 0.3em;
      }
    }

    li {
      display: inline-block;
      list-style: none;
    }

    ul {
      padding-left: 0;
    }

    .footer-menu {
      @include link($color: $cigi-medium-light-grey, $hover-color: $white);

      li {
        padding: 0 1em;
      }
    }

    .social-links {
      li {
        padding: 0 0.5em;
        height: 25px;

        a {
          color: $white;

          &:hover {
            svg {
              fill: $cigi-primary-colour;
            }

            i {
              color: $cigi-primary-colour;
            }
          }

          i {
            transition: color 0.2s ease;
          }

          svg {
            width: 1.15rem;
            height: 1.15rem;
            fill: $white;
            margin-bottom: 0.3rem;
            transition: fill 0.2s ease;
          }
        }
      }
    }

    .copyright {
      color: $cigi-medium-light-grey;
    }

    .footer-menu,
    .copyright {
      font-size: 0.875em;
    }
  }
}
