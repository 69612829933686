.stream-accordion-block {
  border-bottom: 1px solid $cigi-light-grey;
  border-top: 1px solid $cigi-light-grey;
  margin-bottom: 1em;

  button {
    align-items: center;
    color: $cigi-dark-grey;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-between;
    padding: 1em 0;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      color: $cigi-primary-colour;
    }

    &::after {
      border: 0;
      color: $cigi-primary-colour;
      content: '\f078';
      font-family: 'Font Awesome 5 Pro';
      height: 100%;
      margin-left: 0;
      margin-right: 1em;
      transform: rotate(0deg);
      transition: transform 0.25s ease;
      vertical-align: 0.255em;
      width: 0.75em;
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .card {
    border: 0;
  }
}
