$fa-font-path: '../fontawesome-pro/webfonts';
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import 'global/bootstrap_variables_overwrite';
@import '~bootstrap/scss/bootstrap';

@import 'global/resets';
@import 'global/mixins';
@import 'global/global';
@import 'global/helpers';
@import 'global/typography';
@import 'global/swiper';

@import 'layout/grids';
@import 'layout/hero';
@import 'layout/sections';
@import 'layout/tables';
@import 'layout/forms';

@import 'includes/footer';
@import 'includes/top_bar';
@import 'includes/people_person_leadership_list';
@import 'includes/multimedia_list';
@import 'includes/publications_featured_list';
@import 'includes/hero_publication';
@import 'includes/hero_publication_book';
@import 'includes/hero_publication_quick_insights';
@import 'includes/hero_event';
@import 'includes/hero_article';
@import 'includes/hero_plain';
@import 'includes/social_links';
@import 'includes/social_links_square';
@import 'includes/date';
@import 'includes/authors';
@import 'includes/topics';
@import 'includes/about_the_author';
@import 'includes/disclaimer';
@import 'includes/recommended';
@import 'includes/contact_info';
@import 'includes/promotion_block';
@import 'includes/from_the_archives';

@import 'includes/features/feature_content';
@import 'includes/features/feature_content_xlarge';
@import 'includes/features/feature_content_large';
@import 'includes/features/feature_content_medium';
@import 'includes/features/feature_content_small';
@import 'includes/features/feature_content_row';
@import 'includes/features/feature_publication_teaser';
@import 'includes/features/feature_expert_short';
@import 'includes/features/feature_poster_teaser';
@import 'includes/features/feature_event';

@import 'streams/accordion_block';
@import 'streams/additional_file_block';
@import 'streams/autoplay_video_block';
@import 'streams/block';
@import 'streams/block_quote_block';
@import 'streams/chart_block';
@import 'streams/contact_email_block';
@import 'streams/contact_person_block';
@import 'streams/embedded_multimedia_block';
@import 'streams/embedded_video_block';
@import 'streams/external_quote_block';
@import 'streams/external_video_block';
@import 'streams/files_block';
@import 'streams/image_full_bleed_block';
@import 'streams/image_scroll_block';
@import 'streams/inline_video_block';
@import 'streams/line_break_block';
@import 'streams/paragraph_block';
@import 'streams/persons_list_block';
@import 'streams/poster_block';
@import 'streams/pull_quote_right_left_block';
@import 'streams/read_more_block';
@import 'streams/recommended_block';
@import 'streams/table_block';
@import 'streams/text_border_block';
@import 'streams/tool_tip_block';
@import 'streams/tweet_block';

@import 'pages/404';
@import 'pages/people';
