.contact-info-section {
  margin-bottom: 3em;

  h2 {
    color: $cigi-medium-grey;
    font-size: 0.75em;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .contact-info {
    border-bottom: 1px solid $cigi-light-grey;
    margin-bottom: 1.5em;
    padding-bottom: 1.5em;
  }

  .contact-general-inquiries {
    @include link-underline;

    i {
      color: $cigi-primary-colour;
      padding-right: 5px;
    }

    .contact-address {
      display: flex;
      margin-top: 0.5em;

      i {
        width: 25px;
      }
    }
  }
}
