.tool-tip-block {
  @include media-breakpoint-up(lg) {
    max-width: 1100px;
  }
  background: $light-box-grey;
  box-shadow: 0 2px 6px $cigi-dark-grey;
  display: none;
  font-size: 0.9em;
  margin: 0 auto;
  max-width: 95%;
  padding: 2em;
  position: absolute;
  right: 0;
  z-index: 5;

  .person-info {
    display: flex;
    line-height: 1.25;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img {
      border-radius: 50%;
      height: 40px;
      margin-right: 1.5em;
      width: 40px;
    }

    .person-name {
      font-weight: 500;
    }
  }

  .triangle {
    background: $light-box-grey;
    bottom: -9px;
    box-shadow: 3px 3px 3px 0 $cigi-medium-light-grey;
    display: block;
    height: 20px;
    left: 20px;
    position: absolute;
    transform: rotate(45deg);
    width: 20px;
    z-index: -1;

    &::after {
      content: '';
      display: block;
      height: 100px;
      left: -50px;
      position: absolute;
      top: -50px;
      width: 100px;
    }
  }

  .triangle-top {
    bottom: auto;
    box-shadow: 1px 1px 0 0 $dark-box-grey;
    top: -9px;
    transform: rotate(-135deg);
  }

  .tooltip-close {
    color: $cigi-dark-grey;
    cursor: pointer;
    font-size: 1.5em;
    position: absolute;
    right: 10px;
    top: 8px;
  }
}

.text-bubble-link {
  cursor: pointer;

  a {
    &::after {
      color: $cigi-primary-colour;
      content: '\f24d';
      font-family: 'Font Awesome 5 Pro';
      padding-left: 0.25em;
    }
  }
}
