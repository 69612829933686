.featured-medium {
  justify-content: space-between;

  hr {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.feature-content-medium {
  @include image-button($size: 15vw);
  @include media-breakpoint-up(md) {
    @include image-button($size: 3em);
  }
  @include hover-darken;

  .img-wrapper {
    img {
      aspect-ratio: 4 / 3;
      object-fit: cover;
    }
  }

  .feature-content-text {
    margin-top: 0.75em;
  }

  .button-action {
    margin-top: 0.5em;
  }
}
