.stream-block-pull-quote {
  @include media-breakpoint-up(md) {
    width: 40%;
  }

  &.stream-block {
    margin: 1em 0;
  }

  &.left {
    @include media-breakpoint-up(md) {
      float: left;
      padding-right: 1.5em;
    }
  }

  &.right {
    @include media-breakpoint-up(md) {
      float: right;
      padding-left: 1.5em;
    }
  }

  blockquote {
    background: $dark-box-grey;
    border-left: 2px solid $black;
    font-size: 1.25em;
    line-height: 1.5;
    padding: 1em 1.5em;

    .stream-block-pull-quote-author,
    .stream-block-pull-quote-author-title {
      font-size: 0.8em;
    }

    .stream-block-pull-quote-author {
      font-weight: 600;
      margin-top: 0.8em;
    }
  }
}
