//BOOTSTRAP OVERWRITE

$font-family-base: 'futura-pt', sans-serif;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.3125;
$h3-font-size: $font-size-base * 1.125;
$h4-font-size: $font-size-base * 0.75;

$link-color: #e6023b;
$link-decoration: none;
$link-hover-color: #bf0332;
$link-hover-decoration: none;
