.stream-block-autoplay-video {
  video {
    width: 100%;
  }

  .caption {
    border-left: solid 5px $black;
    color: $cigi-text-grey;
    font-size: 0.875em;
    margin: 0.5rem auto 2rem;
    padding: 0.2rem 0 0.2rem 0.5rem;
  }
}
