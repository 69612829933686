.publications-featured-list-row {
  align-content: stretch;
}

.publications-featured-list-col {
  margin-bottom: 2em;
}

.publications-featured-list {
  display: block;
  width: 100%;

  .publications-featured-list-inner {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;

    & > a {
      flex: 0 1 35%;
      margin-right: 2em;
      max-width: 35%;

      &:hover {
        .publications-featured-list-image-wrapper {
          box-shadow: 0 0 20px 0 $extra-light-box-shadow;
          opacity: 1;
          transform: translate3d(0, -0.3125em, 0);
        }
      }

      .publications-featured-list-image-wrapper {
        @include aspect-ratio(8, 11);
        border: 1px solid $cigi-light-grey;
        opacity: 0.85;
        transition: box-shadow 300ms ease 0s, opacity 300ms ease 0s, transform 300ms ease 0s;
        width: 100%;
      }
    }
  }

  .publications-featured-list-content {
    flex: 1 1 65%;

    .publications-featured-list-topics {
      font-weight: 500;
      margin-bottom: 0.5em;
    }

    .publications-featured-list-title {
      @include link($color: $black, $hover-color: $cigi-primary-colour);
      font-size: 1.0625em;
      font-weight: 400;
      margin-bottom: 0.25em;
    }

    .publications-featured-list-date,
    .publications-featured-list-authors {
      color: $cigi-text-grey;
      font-size: 0.75em;
      font-weight: 400;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}
