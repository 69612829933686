body {
  &.template-404 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;

    .hero-plain {
      @include media-breakpoint-up(md) {
        padding-top: 10em;
      }
    }

    .body {
      margin-bottom: 3em;
    }
  }
}
