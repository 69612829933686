section {
  &.recommended-section {
    @include media-breakpoint-up(md) {
      margin-top: 4em;
    }
    margin-bottom: 2em;
    margin-top: 3em;

    .recommended {
      align-items: center;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 1.25em;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 0.8em;
        margin-top: 0.8em;
      }

      .recommended-dot {
        @include media-breakpoint-up(sm) {
          display: block;
        }
        background-color: $black;
        border-radius: 50%;
        display: none;
        height: 0.4rem;
        width: 0.4rem;
      }

      .recommended-vertical-line {
        @include media-breakpoint-up(sm) {
          display: block;
        }
        border-left: 1px solid $cigi-medium-light-grey;
        display: none;
        height: 100px;
        left: 50%;
      }

      .footer-bracket {
        @include media-breakpoint-up(sm) {
          display: block;
        }
        display: none;
        margin-bottom: 1em;
        width: 100%;
      }

      .recommended-content-medium {
        .col-md-4 {
          article {
            border-top: 1px $cigi-light-grey solid;
            padding-bottom: 1em;
            padding-top: 1em;

            .feature-content-text {
              @include media-breakpoint-down(sm) {
                margin-top: 0;
              }
            }
          }

          &:nth-child(-n + 3) {
            article {
              @include media-breakpoint-up(md) {
                border-top: 0;
              }
            }
          }

          &:first-child {
            article {
              border-top: 0;
            }
          }
        }

        .img-wrapper {
          @include media-breakpoint-up(md) {
            display: block;
          }
          display: none;
        }
      }

      .feature-content-small {
        display: flex;

        .table-icon {
          @include media-breakpoint-down(sm) {
            display: none;
          }
          margin-top: 0.4em;
        }

        .topics,
        .feature-content-date {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }
}
