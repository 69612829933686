.people-person-leadership-list-section {

  .people-person-leadership-nav {
    align-items: center;
    display: flex;
    margin-bottom: 1em;

    .people-person-leadership-nav-text {
      color: $cigi-medium-grey;
      flex: 0 0 auto;
      font-size: 0.9em;
      font-weight: 400;
      margin-right: 1em;
    }

    .nav-tabs {
      border: 0;

      .nav-item {
        align-items: center;
        display: inline-flex;
        position: relative;

        &:not(:first-child) {
          padding-left: 1em;

          &::before {
            color: $cigi-medium-grey;
            content: '|';
            display: inline-flex;
            font-weight: 300;
            left: 0;
            position: absolute;
            top: 0;
          }
        }
      }

      .nav-link {
        border: 0;
        border-bottom: 3px solid transparent;
        color: $black;
        font-size: 0.875em;
        font-weight: 700;
        margin-right: 1em;
        padding: 0;

        &.active {
          border-bottom-color: $cigi-primary-colour;
        }
      }
    }

    @media(max-width: 374.98px) {
      align-items: baseline;

      .nav-tabs {
        flex-direction: column;

        .nav-item {
          &:not(:first-child) {
            padding-left: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }


  .people-person-leadership-list {
    list-style: none;

    li {
      border-bottom: 1px solid $cigi-light-grey;
      padding: 1em 0;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .people-person-leadership-list-info {
      align-items: center;
      display: flex;
      margin-bottom: 1em;
    }

    .people-person-leadership-list-photo {
      @include aspect-ratio(1, 1);
      border-radius: 100%;
      display: inline-block;
      flex: 0 0 70px;
      height: 70px;
      margin-right: 1em;
      width: 70px;
    }

    .people-person-leadership-list-name {
      color: $black;
      font-size: 1.0625em;

      &:hover {
        color: $cigi-primary-colour;
      }
    }

    .people-person-leadership-list-function {
      color: $cigi-text-grey;
      font-size: 0.9em;
    }
  }
}
