.stream-block-inline-video {
  @include video-aspect-ratio(4, 3);
  margin-bottom: 2em;
  margin-top: 2em;

  .stream-block-inline-video-content {
    @include hover-darken;
    @include image-button($size: 40px);
    border-bottom: 1px solid $cigi-light-grey;
    border-top: 1px solid $cigi-light-grey;
    padding: 1em 0;

    .img-wrapper {
      @include media-breakpoint-up(md) {
        display: block;
      }

      cursor: pointer;
      display: none;
      flex-shrink: 0;
      margin-right: 1em;
      position: relative;
      transition-duration: 1.5s;
      transition-property: width, height, margin-right;
      transition-timing-function: ease;
      width: 250px;

      &.shrink {
        margin-right: 0;
        width: 0;

        .image-button {
          opacity: 0;
          transition: opacity 1s ease;
        }
      }
    }

    .stream-block-inline-video-text {
      @include link($color: $black, $hover-color: $cigi-dark-pink);
      justify-content: center;
      margin-bottom: 0.3em;

      a {
        font-size: 1.3em;
        font-weight: 500;
        line-height: 1.2em;
      }

      i {
        color: $cigi-primary-colour;
      }

      .watch-video-label {
        color: $cigi-primary-colour;
        cursor: pointer;
        font-weight: 500;
        max-height: 2em;
        overflow: hidden;
        transition: max-height 1.5s ease;

        &:hover {
          color: $black;
        }

        &.shrink {
          max-height: 0;
        }
      }
    }

    .close-video-icon {
      cursor: pointer;
      margin-left: auto;
      transition: opacity 1.5s ease, color 0.2s ease;

      &:hover {
        color: $cigi-dark-pink;
      }

      &.hidden {
        opacity: 0;
      }
    }
  }

  .video-wrapper {
    height: 100%;
    transition-duration: 1.5s;
    transition-property: width, height, padding;
    transition-timing-function: ease;
    will-change: auto;

    &.shrink {
      height: 0;
      padding-top: 0;
      width: 0;
    }
  }
}
