// META
.meta {
  color: $cigi-text-grey;
  font-size: 0.75rem;
  text-transform: uppercase;

  &.meta-title {
    color: $black;
  }
}

// PARAGRAPHS
.paragraph-heading {
  border-top: 0.2rem solid $black;
  color: $black;
  display: inline-block;
  font-size: 0.75em;
  font-weight: $sans-medium;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

// LISTS
.custom-text-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  li {
    @include comma-separated;
  }
}

.inline-list {
  display: flex;
  list-style: none;

  li {
    margin-right: 0.2em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dropdown-button-list {
  .button {
    background: $color--blue;
    text-align: left;
    width: 150px;

    &:hover {
      color: $white;
    }

    .fas {
      float: right;
    }
  }

  ul {
    background: $color--blue;
    display: none;
    list-style: none;
    margin-top: -0.8em;
    text-align: left;
    width: 150px;
  }

  li {
    margin: 0;

    a {
      font-size: 0.75em;
      padding: 7px 10px;
      text-align: center;

      &:hover {
        background: $color-dark-blue;
        color: $white;
      }
    }
  }

  &:hover {
    ul {
      display: block;
      position: absolute;
    }
  }
}

// FLEX-BOX
.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

// BORDERS
.border-round {
  border-radius: 50%;
}

// BUTTONS
.button {
  background-color: $color--blue;
  border-radius: 2px;
  color: $white;
  font-size: 0.75em;
  line-height: 1.1em;
  margin: 1em 0;
  padding: 7px 10px;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: $color-dark-blue;
  }

  a {
    color: $white;
  }
}

.button-action {
  background-color: transparent;
  border: 1px solid $cigi-primary-colour;
  border-radius: 2px;
  color: $cigi-primary-colour;
  display: inline-flex;
  font-size: 0.625em;
  line-height: 1.1;
  padding: 0.7em 1em;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.25s ease, color 0.25s ease;

  &:hover {
    background-color: $cigi-primary-colour;
    color: $white;
  }

  &.disabled {
    border-color: $cigi-medium-grey;
    color: $cigi-medium-grey;
    cursor: default;

    &:hover {
      background-color: transparent;
      color: $cigi-medium-grey;
    }
  }

  &.button-square {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-size: 0.75em;
    height: 25px;
    justify-content: center;
    margin-top: 0.5em;
    padding: 7px 10px;
    width: 25px;

    i {
      margin-right: 0;
    }
  }

  i {
    margin-right: 0.4em;
  }
}

.simple-listing {
  border-bottom: 1px solid $cigi-light-grey;
  margin-bottom: 1em;
  padding-bottom: 1em;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .article-authors {
    @include link($color: $cigi-text-grey, $hover-color: $cigi-primary-colour);

    a {
      @include comma-separated;

      &:not(:only-child) {
        &::after {
          margin-right: 0;
        }
      }
    }
  }

  .article-title {
    @include link($color: $black, $hover-color: $cigi-primary-colour);
    font-size: 1.0625em;
  }

  .article-authors,
  .article-date {
    color: $cigi-text-grey;
    font-size: 0.75em;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

.table-icon {
  @include media-breakpoint-down(sm) {
    display: none;
  }

  align-items: center;
  border: 1px solid;
  border-radius: 100%;
  display: inline-flex;
  flex: 0 0 2em;
  height: 2em;
  justify-content: center;
  margin-right: 0.625em;
  width: 2em;

  &.icon-event {
    border-color: $color-event;
    color: $color-event;
  }

  &.icon-multimedia {
    border-color: $color-multimedia;
    color: $color-multimedia;
  }

  &.icon-opinion {
    border-color: $color-opinion;
    color: $color-opinion;
  }

  &.icon-publication {
    border-color: $color-publication;
    color: $color-publication;
  }

  &.icon-media {
    border-color: $color-media;
    color: $color-media;
  }

  &.icon-person {
    border-color: $color-person;
    color: $color-person;
  }

  &.icon-topic {
    border-color: $color-topic;
    color: $color-topic;
  }

  &.icon-opinion-series {
    border-color: $color-opinion;
    color: $color-opinion;
  }

  &.icon-activity {
    border-color: $color-activity;
    color: $color-activity;
  }
}
