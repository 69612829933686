.hero-plain {
  padding-top: 6em;

  h1 {
    font-size: 2.75em;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 1em;
  }
}
