body {
  a {
    transition: color 0.2s ease;
  }

  h2,
  h3,
  h4 {
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  figcaption {
    color: $cigi-text-grey;
    font-size: 0.9em;
    line-height: 1.2em;
    padding: 0.25em 0;
    text-align: left;
  }
}

.body {
  h2,
  h3,
  h4 {
    margin-top: 1.25rem;

    &:first-child {
      margin-top: 1rem;
    }
  }

  h4 {
    color: $cigi-dark-grey;
    font-weight: 500;
    text-transform: uppercase;
  }

  ul,
  ol {
    padding-left: 1.75em;
  }

  .topics {
    padding-left: 0;
  }
}
