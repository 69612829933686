article {
  &.feature-poster-teaser {
    @include hover-darken;

    .img-wrapper {
      color: transparent;
      display: block;
      margin-bottom: 1em;

      img {
        border: 1px solid $cigi-light-grey;
        box-shadow: -5px 5px 10px $cigi-light-grey;

        &:hover {
          border: 1px solid $white;
        }
      }
    }

    .topics {
      margin-bottom: 0.5em;
    }
  }
}
