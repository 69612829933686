section {
  &.hero {
    @include media-breakpoint-up(md) {
      padding-top: 10em;
    }
    background-color: $black;
    font-size: 1rem;
    padding-bottom: 3em;
    padding-top: 5em;
    position: relative;

    .hero-container {
      .hero-main {
        h1 {
          @include media-breakpoint-up(md) {
            font-size: 2.5em;
          }
          color: $white;
          font-size: 1.75em;
          font-weight: $sans-regular;
          margin-bottom: 0.5em;
        }

        h2 {
          color: $cigi-medium-light-grey;
          font-size: 1em;
          font-weight: $sans-regular;

          p {
            line-height: 1.5;
          }
        }

        .hero-links {
          margin-top: 1.5em;

          .hero-link {
            @include link($color: $white, $hover-color: $cigi-primary-colour);
            margin-top: 0.5em;

            i {
              border: 2px solid $white;
              border-radius: 50%;
              color: $white;
              font-size: 12px;
              margin-right: 6px;
              padding: 10px;
              transition: color 0.2s ease, border-color 0.2s ease;
            }

            a {
              &:hover {
                i {
                  border-color: $cigi-primary-colour;
                  color: $cigi-primary-colour;
                }
              }
            }
          }
        }

        .topics {
          @include link($color: $white, $hover-color: $cigi-primary-colour);
          margin-bottom: 1em;
        }
      }

      aside {
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
        margin-top: 2em;

        nav {
          h2 {
            color: $white;
            font-size: 0.75em;
            font-weight: 500;
            text-transform: uppercase;

            span {
              border-top: 3px solid $white;
            }
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            &.multicol-2 {
              columns: 2;
            }

            li {
              @include link($color: $white, $hover-color: $cigi-primary-colour);
              border-bottom: 1px solid $border-grey;
              break-inside: avoid-column;
              color: $cigi-medium-light-grey;
              padding-bottom: 0.2em;
              padding-top: 0.2em;
            }
          }
        }
      }
    }

    .banner-bg {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &.gradient {
      .banner-bg {
        &::after {
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.65) 0%,
            rgba(0, 0, 0, 0.1) 50%
          );
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &::before {
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.1) 50%,
            rgba(0, 0, 0, 0.65) 100%
          );
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}
