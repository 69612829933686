article {
  &.feature-expert-short {
    .feature-expert-latest-activity {
      h4 {
        color: $cigi-text-grey;
        font-family: 0.75em;
        margin-top: 1.3em;
        text-transform: uppercase;
      }

      h5 {
        @include link($color: $black, $hover-color: $cigi-primary-colour);
        font-size: 1.0625em;
        font-weight: 400;
        line-height: 1.4;
      }

      .authors {
        margin: 0;
      }
    }

    .feature-expert-person-details {
      align-items: center;
      display: flex;

      img {
        border-radius: 50%;
        height: 70px;
        margin-right: 10px;
        width: 70px;
      }

      .feature-expert-person-title {
        h3 {
          @include link($color: $black, $hover-color: $cigi-primary-colour);
          font-size: 1.0625em;
          margin-bottom: 0;
        }

        p {
          color: $cigi-text-grey;
          font-size: 0.9em;
          margin-bottom: 0;
        }
      }
    }
  }
}
