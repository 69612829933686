section {
  &.hero-quick-insights {
    background-color: $white;
    padding-bottom: 0;

    .topics {
      margin: 1em 0;
    }

    h1 {
      @include media-breakpoint-up(md) {
        font-size: 2.75em;
      }
      font-size: 1.75em;
      font-weight: 400;
      line-height: 1.1em;
    }

    .authors {
      @include link(
        $color: $cigi-text-grey,
        $hover-color: $cigi-primary-colour
      );
      text-transform: uppercase;
      font-size: 0.875rem;
      margin-bottom: 0.25em;
    }

    .date {
      text-transform: uppercase;
      font-size: 0.875rem;
    }

    .social-share-list {
      justify-content: left;
      margin-bottom: 0;

      &.no-stack {
        li {
          a {
            margin-right: 0;

            &:hover {
              i {
                color: $cigi-primary-colour;
              }
            }

            i {
              transition: color 0.2s ease;
            }
          }
        }
      }
    }
  }
}
