.feature-content-small {
  .feature-content-title {
    font-size: 1.0625em;
    margin-bottom: 0.25em;
  }

  .button-action {
    margin-top: 0.5em;
  }
}

// Column on home page and article landing page
// that contains small feature items
.featured-small {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
